import { createRouter, createWebHashHistory } from "vue-router";

let routes = [
  {
    path: "/",
    redirect: "/home/homeView",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
    redirect: "/home/homeView",
    children: [
      {
        path: "homeView",
        name: "homeView",
        component: () => import("@/components/Home/index.vue"),
        meta: {
          active: 1,
        },
      },
      {
        path: "bannerDetail",
        name: "bannerDetail",
        component: () =>
          import("@/components/Home/components/bannerDetail.vue"),
        meta: {
          active: 1,
        },
      },
      {
        path: "homeNoticeDetail",
        name: "homeNoticeDetail",
        component: () =>
          import("@/components/Home/components/noticeDetail.vue"),
        meta: {
          active: 1,
        },
      },
      {
        path: "matchCenter",
        name: "matchCenter",
        component: () => import("@/components/matchCenter/index.vue"),
        meta: {
          active: 2,
        },
      },
      {
        path: "matchDetail",
        name: "matchDetail",
        component: () =>
          import("@/components/matchCenter/components/matchDetail.vue"),
        meta: {
          active: 2,
        },
      },
      {
        path: "notice",
        name: "notice",
        component: () => import("@/components/notice/index.vue"),
        meta: {
          active: 3,
        },
      },
      {
        path: "noticeDetail",
        name: "noticeDetail",
        component: () =>
          import("@/components/notice/components/noticeDetail.vue"),
        meta: {
          active: 3,
        },
      },
      {
        path: "matchVideo",
        name: "matchVideo",
        component: () => import("@/components/matchVideo/index.vue"),
        meta: {
          active: 4,
        },
      },
      {
        path: "matchVideoDetail",
        name: "matchVideoDetail",
        component: () =>
          import("@/components/matchVideo/components/matchVideoDetail.vue"),
        meta: {
          active: 4,
        },
      },
      {
        path: "vipServe",
        name: "vipServe",
        component: () => import("@/components/VipServe/index.vue"),
        meta: {
          active: 5,
        },
      },
      {
        path: "shopDetail",
        name: "shopDetail",
        component: () =>
          import("@/components/VipServe/components/shopDetail.vue"),
        meta: {
          active: 5,
        },
      },
      {
        path: "clube",
        name: "clube",
        component: () => import("@/components/clube/index.vue"),
        meta: {
          active: 6,
        },
      },
      {
        path: "clubeDetail",
        name: "clubeDetail",
        component: () => import("@/components/clube/components/detail.vue"),
        meta: {
          active: 6,
        },
      },
      {
        path: "dartStudy",
        name: "dartStudy",
        component: () => import("@/components/dartStudy/index.vue"),
        meta: {
          active: 7,
        },
      },
      {
        path: "dartStudyDetail",
        name: "dartStudyDetail",
        component: () => import("@/components/dartStudy/components/detail.vue"),
        meta: {
          active: 7,
        },
      },
      {
        path: "gonggao",
        name: "gonggao",
        component: () => import("@/components/gongao/index.vue"),
        meta: {
          active: "",
        },
      },
      {
        path: "matchguide",
        name: "matchguide",
        component: () => import("@/components/matchGuide/index.vue"),
        meta: {
          active: 8,
        },
      },
      {
        path: "aboutSociety",
        name: "aboutSociety",
        component: () => import("@/components/aboutSociety/index.vue"),
        meta: {
          active: 9,
        },
      },
    ],
  },
  {
    path: "/cart",
    name: "cartview",
    component: () => import("@/views/CartView.vue"),
  },
];

// 3. 创建路由实例并传递 `routes` 配置。
const router = createRouter({
  // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  // 将通用路由和模块路由合并
  routes: routes,
});

export default router;
