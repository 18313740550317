import { createApp } from "vue";
import App from "./App.vue";
// import "@/utils/rem";
import "vant/lib/index.css";
import { Button, Swipe, SwipeItem, ConfigProvider } from "vant";
// 引入路由
import router from "./router";
import "./assets/baseCss.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
//引入iconfont
import "./assets/iconfont/iconfont.css";
let app = createApp(App);
app.use(router);
app.use(Swipe);
app.use(SwipeItem);
app.use(Button);
app.use(ConfigProvider);
app.use(ElementPlus);
app.mount("#app");
